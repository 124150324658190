import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@robinguan/gatsby-mdx-mui-theme/src/components/Layout.js";
import { Section, Button, ImageContainer, TwoColumnsGrid, FourColumnsGrid, Chips, Buttons } from "@robinguan/gatsby-mdx-mui-theme";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section background={"#D0CDE1"} mdxType="Section">
      <TwoColumnsGrid left={<ImageContainer width={'100%'} border={1} borderColor={'#D0CDE1'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAABpElEQVQ4y6WTTU7DMBCFcz72LNizgitwGk7Aigsg2CFB2zQpbRdQqv6qieqmTfwzj3HsRm4kJCosfXqJO/OcGU+jzWpqVqsJ5cs+CbEjsd06FXvHrkW9XzCC2XptMJE2wDDtUZF/EQCPIpiC2TGihd3fcwwF8Q2IiAw0u9Y7RtWqlw+QbxeQyTVkeuu5cfSuUHUu2fPT5XA+sbeHosad/LIxaktSjEkWE0K1ICrnRJWn/CZzmLCPdH6tFQXu/kSniltRcY7hlzYuDv47cLKi8CU0V1IizzNUVQljDIzWrLpWMq7MY86fDLVS4Btnw8oZBhxjzjJUbGixz/82tEvakrOsLt0ZKS6Xn7U60xC+ZO6VlKr5TTPGqr2U83vIifwlaecdry/PWC9mwOIeanwHs35s5u+sHtoy14s5Zt8TFEKAdl3o7In/KCO4Yf3FkBPJN/uodbn2Qk4O8yXXWo/QyUU1HlEQ36zycMBsOkW22aAsS+Q8OgVfSsVhBQitWQ7PRDQcDIhBoEj7MeJuB72O5R1d1jiOkSQJ+kyaJjjGhrmjj4H5AbRXjY28p729AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "scca",
              "title": "scca",
              "src": "/static/3b4e81de84aabf388ef625dbff891a64/c1b63/scca-1.png",
              "srcSet": ["/static/3b4e81de84aabf388ef625dbff891a64/5a46d/scca-1.png 300w", "/static/3b4e81de84aabf388ef625dbff891a64/0a47e/scca-1.png 600w", "/static/3b4e81de84aabf388ef625dbff891a64/c1b63/scca-1.png 1200w", "/static/3b4e81de84aabf388ef625dbff891a64/71b12/scca-1.png 1308w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} right={<div>
        <h6>🏥 Seattle Cancer Care Alliance</h6>
        <p>Two major products I worked on, web-based applications written in React and Redux for patients to view appointments, complete surveys, and review lab results. Improved patient response rate and overall user experience, a microservice for scheduling patients' surveys based on their appointments, disease groups, and other business requirements.</p>
        <Chips chips={['React', 'Redux', 'Sass', 'JavaScript', 'AWS Lambda', 'Postgres', 'Severless Architecture', 'Jest', 'Mocha', 'Chai']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://caresi.seattlecca.org'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#fff"} mdxType="Section">
      <TwoColumnsGrid right={<ImageContainer width={'100%'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "85.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAAEZ0lEQVQ4y32Ta0wUVxiGt5a0TVp/tImN9hIqbYyxiCLYKqLo7mKFUqtUtJXUxP5oSFMLUmLU0EoQowTxSkkJBFcFuRWQAm1SLXfEyrKU24puQdiFhd2Z3dn7LnN25u2ZSWn6o+2XPHm/c+bkPd93zhnFyrCwnC2xWx+rVPH9O5Qq3WurInXLVkbotsUpdUqVWifNSaj+kSuVat32HSqKtCZeuyEqSr9i+fINCinCw9c26R9PwO5wY3rWiuaeMdTeHYTZaofL7YXT5YHT7QHrcMDhlnI3VQd8fj9snBN8UER5uQbUapdsGBERUWOemwMN3uP1ChCIAAQFn88r+P1+QQgG6RjCKDMhCKJI84BgdA0LnIsXREH+tnDrVqVkGL9oWDs5+QRen4/YOQ4WKwOHwwm7nYONtcFsY/DA+Aj90+PQGQ34w2JEn0GPn/tHwdlsIt2PaDQahISE/F1hrck4Bb/XTTy0FbeTg8/ng9/vpTUT6GYGsafhEJS3DyOhOQ2pLafw/u0slAyWgrMwdAkhZWVlUoUxsuFaath45z5ae/WkqXMETR1DYO0ODBtMaGnX48pP1ThYl4rCgUOo0X2Gm20foWbgKMYtF+BxE3g8nmBbWxvq6uov0fZXyBUaJqfg8gYIY3dCwu8PUOVgZTwYoy2XPu7AhG0SPu4hAtwYAn4jWJsLM3MWPDRMwmieJ9IlzFmsN2RDzm6XxvKkFAsLAQT4IHi7Ca7uEpA+DbjOMjBd5WA7y+Htr8LVy+cRtetDbEo5KMYm7w8qUz5B0TVNjWLdunW1NptNNhRpUBUFgcg6x7aJtR0viPVdL4oNlEbKDx1LxV+0oeKxM2li6DtqRCUli+8m7SWrVLuQd6WoXrF69era+fl52ZDnedAzhqz0QXgZE9jum7D3VlIqYO+poOMbCOjq8U3+RaxJ/Fjcti8V6z9IIasSk5Fz5btqRUxMTPWXR44gMzMzkJGRQRZJT0/nj2ZmBb8+kSNmnji1CPnqWDY5dfocOXw8TwxNOICNew6I4QnJ5M339iH7QlGVwmgytbjo6582GjFjNmNmdlZmluZTU1MwGB4JFJECk3EaRoqDXlT2pWK8EqsSw3cm4q3tiWTF1iScLLhapaB/yX4rwxQzLFtAKZSg44ucw3F2TK/v7uu7j57eXqH33r0A3fS6xWrNJ4S/lJ17bm7T5s1Qq+OEiDg1WRatxvEzhVWK/4vB34eyDYYJPOjXCv1aXVCr1YUtfstM+6Jvd9Qa7I2LDG5U7SSvbkmghuerFLS1pyhLaLtLJJ188uTp3amfKliWfenur+09t5ua0dDY5P6xuRVdXT3fSmb0Ap/9PGX/b9FLn8P6N17nn4/cyj9DK0w/XXBLQc9LZvYv1WoH5AqGhoZfbm5pjS4qKn77+5LStSUlpRHt7R2RjRWVIdRwScU1zcjF3DyczcnFyfzLyCutRMOdjsZ/bXVkZPQ/j4Gayco53cULQdHsdHvHfV7fOFngzQzDZv0JQPqBeqqwrUAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Net-Inspect",
              "title": "Net-Inspect",
              "src": "/static/aed2d1a3503240e6466cf7c65f6a4d5b/c1b63/netinspect-1.png",
              "srcSet": ["/static/aed2d1a3503240e6466cf7c65f6a4d5b/5a46d/netinspect-1.png 300w", "/static/aed2d1a3503240e6466cf7c65f6a4d5b/0a47e/netinspect-1.png 600w", "/static/aed2d1a3503240e6466cf7c65f6a4d5b/c1b63/netinspect-1.png 1200w", "/static/aed2d1a3503240e6466cf7c65f6a4d5b/d61c2/netinspect-1.png 1800w", "/static/aed2d1a3503240e6466cf7c65f6a4d5b/764a0/netinspect-1.png 2025w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} left={<div>
        <h5>📏 Net-Inspect</h5>
        <p>Designed and implemented 3 major modules in a suite of web-based supply chain management tools. Brainstormed, prototyped, and created data-driven interactive charts; transformed complex concepts and data into visually engaging information according to modern design principles. Collaborated with business stakeholders and the engineering team to develop project requirements.</p>
        <Chips chips={['User Interface Design', 'User Experience Design', 'Product Owner', 'Front-end Development', 'wireframes', 'design docs', 'functional specifications']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://www.net-inspect.com'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#D0CDE1"} mdxType="Section">
      <TwoColumnsGrid left={<ImageContainer width={'100%'} border={1} borderColor={"#D0CDE1"} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAADPElEQVQ4y3WT309bZRjH+z/o1eIy5oyRuYFmRub8hW6ReKkXM/FyV8suzTQukV9jOiAYL1wY0w4lsRpURGTLcHGbMEpbfoyuLW1padlYgban5/Scnhba0/b9eE6FzBuf5Jvned83z/d93+f5PjYtkxDhB2s82siIQl5H0zSM0jaIMoVCnlwuV4O+63ULei0ulUpUq1Vyel5IkmzlCptRhtueFeFbzVIV1Ox+OMHP1+eRsgV2rVJ97Ms7i/ByhOFfRlnw+ikLhDDzbeYNIrdlsG1UaxuWLcfWGZ2YxelaIBgM1RCJxojFV5HTErlslryaJRIOc+uv2wS8i2JjNU6pWBQ2iyVvflPLqmiqRnozRXlri+VwkD/GJ1hPqaRkHUnJ45nzsjA7jy4rpB4l8M9MIsUjFDWV+b9vocoZbOVymYBviUgwwko4SjQUYWNtHZ83wNSUB/9igJEfh0luSsQebOBd9KNrBbOG2/xq72fkqwvkNtepmL+zHmerVCq4Z9y4pt3MuedwTjlxTt7lzxs3OfvROd5samL/k09w6uQH9HR/ycS1caJLAR6uxPC5XTh62lmddZKVVSwum1Uzxw8Oujov0NvTR2f7eVrbujj7aSsnmt9if90+9u3dS31dHS3NzfT19BJcvI/fNcO1oSvc+cnOw3tuVLPLhtnhGuHvv43R3tZBR1snA/1XuNw/gP3bq5w5fZpXjx2jseEwR48c4cP336PvYi+JSJy4x83Y1cvMjDpIRcNoGcWUm7FDODLGG6+9zitNRznf2cXFL7rp/rybluPHeWrPHp458DQNzx+kqbGBk++2EJqeJu66y/XBASaHvycZXUaTs7uEwqzLDT75+Bxtn3UwaB+i/9I3vPP2CV5uPMwLB+t57sCzNNQf4qVDL9J65hT3xhxMDA0ybr/Eze++Ni+4g5xM/0toFlIkkxJZNSc0TUdVddbWEszNeggFfGb3/WbHvYQDS4T8fjQpiVFQUdIp1EwaZTOBrsg7CkbYKuboJCVFpM0ny1kNRc0hm6T/Z1ZmRTyOd5lM0QtrbC1hV/V8nmKpZEZVLFja1PMFisVibV7/C8MwaueWr8HcswQYMKdJVhTxD4+7afc3LElMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "mightyvet",
              "title": "mightyvet",
              "src": "/static/38691db2df22da28e0dac7aebfbd234d/c1b63/mightyvet-1.png",
              "srcSet": ["/static/38691db2df22da28e0dac7aebfbd234d/5a46d/mightyvet-1.png 300w", "/static/38691db2df22da28e0dac7aebfbd234d/0a47e/mightyvet-1.png 600w", "/static/38691db2df22da28e0dac7aebfbd234d/c1b63/mightyvet-1.png 1200w", "/static/38691db2df22da28e0dac7aebfbd234d/1628f/mightyvet-1.png 1232w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} right={<div>
        <h5>🐶 MightyVet</h5>
        <p>A website for helping veterinary workers tackle issues in their profession and community. Features include the ability for veterinarians to host, schedule, and attend courses or support groups online.</p>
        <Chips chips={['Angular', 'Node.js', 'Express.js', 'MongoDB', 'Stripe', 'Bootstrap']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://mightyvet.org'
      }, {
        label: 'Github',
        color: 'secondary',
        href: 'https://github.com/mighty-vet/MightyVet'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#ffffff"} mdxType="Section">
      <TwoColumnsGrid right={<ImageContainer width={'100%'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "69.66666666666667%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADhUlEQVQ4y52Pe0xTVxzHL1v8Y5io25Ity/4xptFkj39k1TEDsvmHyWKW4MbQZTFsMwGcDDPFbW7iAx+wSYYkFHm0tPRh30xpgfbaax+0FLgCpS3ctlDEOm2hhQhVcNx7fzv3Kpot2T87ySe/7/mek8/JwTC0ItrzWPrRcn5ycbks8WCpZGZhqZQjtfjfzC7wlMynl8sTM7PZnAcAMrAn4V6mkVq6d4AAOIizUGQF+AoHKOx6QoEZ4PPVjNjXDXAA3Sk00fDdAAA5lTJynkWAF54K6bWt5PzEuw0x+Egc+yuvJUbntdyhc5rv0B+2xuhPlX/Se9rv0jtRn4PIRXD9jqbY413KeXBRcS3nmX4uhEzDcDz6iTgI+6WjzD7pKLtf6me/QLNA7GOLrwbYIrmfLZD42MK2UZ7PUP+xiFz5Uj0JvYEp5b++DJmdw7ejpfI+OH9tiDnbcQsqDYNwEvGTdgAq1P3wI5pcPqEbhONo/3uPn3WG4owjlIDkg3R8diZRgVTPhbYRKvqzwgx1xhtMjc4CFzXdPDXaHp5fEdUaLlugRm0C8U0/lBMrUG59uHLICXAaj0+PHX5z3TOhe2Qkekkhh9Y/Oph6tRrqVCq4YtBDrUIBjTodrHaNegPUKWTQ3ONmd0um2b2ySWa39C6UXh0PpqVFrzwT+gL9UY2xEfCbRsbUo4TrXe2AEwbo6GwDi00HZosKdXLuHDrNEjC77PCtkoSvJb10kYyESo1nPCgq27AqXOt2dk9WVR6E6qoy+sKZQwxH9bkyNEsZ1PH7c6dKmAtnDzNVv3zDiGVX2JPKLjjaoqOPy65DrcYUMF2sWL8qXKNQKKaFwm2Qnf0BbN/+PgiFwqds42dW1nuwdWsWP99+6x049kMFyK4p2XqZiG7WSkBuVAXqz9Sux+bm5tal0w9zwuHIMEEQC06HM2m3O1I2m43HasVTFouVB8dvoI5IEYQ96bBb0w2Xj7GXa4+sNNR9D+1tv1EqretVLB5PvOhx979hwx0bw6EpQdAfEowFw4JI+DZiShCmogJqfFIwPjYhCKHsGwluQT96Xa/X1+zNz4fc3Bw2L28nlBQXR5LJ5MvY/10URRX6fL5HJEneHxoaSve63VZUv4RNTESxSDiK3SJ9GR73QEafZ/AfeNyDfM+foeztIzOU7Z1YU1PTGq+3b5PL5RJ4vd7NIpHoNe6hvwFagLTje0XaEQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "washmemaybe",
              "title": "washmemaybe",
              "src": "/static/12ecf13416647e110126446598d30bc7/c1b63/washmemaybe-5.png",
              "srcSet": ["/static/12ecf13416647e110126446598d30bc7/5a46d/washmemaybe-5.png 300w", "/static/12ecf13416647e110126446598d30bc7/0a47e/washmemaybe-5.png 600w", "/static/12ecf13416647e110126446598d30bc7/c1b63/washmemaybe-5.png 1200w", "/static/12ecf13416647e110126446598d30bc7/d61c2/washmemaybe-5.png 1800w", "/static/12ecf13416647e110126446598d30bc7/f349c/washmemaybe-5.png 2002w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} left={<div>
        <h5>🚘 Wash me, maybe?</h5>
        <p>This app solves the age-old problem of washing your car only to have it rain the very next day. This simple app runs across all browsers and mobile devices. By using the magic of GPS and online weather forecasts, <b>Wash me, maybe?</b> will automatically give a simple Yes/No answer on whether you should wash your car today. Additionally, the app shows you a 5-day precipitation forecast to help you plan your next car wash!</p>
        <Chips chips={['React', 'Gatsby', 'Sass', 'Open weather API', 'Geolocation API']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://washmemaybe.com'
      }, {
        label: 'Github',
        color: 'secondary',
        href: 'https://github.com/RobinRobinG/car-wash'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#D0CDE1"} mdxType="Section">
      <TwoColumnsGrid left={<ImageContainer width={'100%'} border={1} borderColor={'#D0CDE1'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1091px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "74%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAADNUlEQVQ4y6WT3U+bZRjG+z/wN3jg5sEyD1wWD1w8cV9OnckEdAmoOJsNMGGOgGYGnYGxstJ1rEOobA6pGPqBtDDabU62VQZrAVuWdWGBrVhx420hAi30fX4+b1saMNET7+R+n49cz/Vc9/tcty4Rm1Znn0ZRns2LeCJOPB4nkdAykU9tb2MqirJpLTEihxU6ZMTnYkKZm+V/htA+OlVVxa2bP+Mb8Ijxe3eJhALMTj+UKuaZn1dYWFggHAoxOTlJJPKAkWCAX4aGGB8fZ3R0lOHhYcbGxkQwGGRxcVHoUqkkh4vf4fXXXuHzyhLMDbVYLxrpsdu5dv0G3qtXuXzpEubzFqqrq3nuxe3seGkHR47oOXSokD179rJv334OHnybcDiMbm1tjY6ONiytLVzu6qT1Gwt227f4vP2Ym414nHZuDzoJ3Rmgq+MitV/UcaruK4qK36OwsJjS0g8oL6+kpKSUkKwkQ/iTVNHd20tPnxuPw4a/v4uJgB+Py46/20jn8TdwNx1jos/ErQEHlUcr2LZtOzt3vsyBA29SU1OLwWBgamoqS2iT5bV9f4VGk5HTZ5rwuh34vU6Cvw4xam9hpNtAd/0xPOYqXG2nOWc6z+7de9my5QVJ+BZGYzPt7e1Eo9EsYYvVyimjgc++/pLnt26lvOITbFc68PR04rOe4WbLca6Zy+mqe5+q4v189GEZH+uPsmvXqxQVvYvJdI7Gxkb5aBF06XQa9+AgP/Y6GbhxnSaTibKyMhwOF/cCI/hcTownKrCcrOJkpZ6aE59iuWDh7FmjJDLRJ3+TzfYDLomLxf5AJ0TGPvw5N0fkQWSTsZTHT1h8+myz2/5a/of91FxmQ/NhZhIKhTOqFCWOKlVr4bnQSv93nXlw9NEUDXo96WQSVRMiM3DXy/2QP0stufIKZ3+PSYNOsLS0xPolPlnOyB1/nvDJ9DTW5mbSq6v5vd+Ct4ncD2ZbRXLpsqNgeXlJbCwklUqxnFzJKElKRVqsrKxkRm2tndEeNJlalWNa5DDZXhY5mdoBt9vNzMxM/kZyFaxXIlT1v3s5i8mAhNafBQUFNNTXZxCaAzQisYH0X3Jdk/gbYHvzU8E9HjAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ecommerce",
              "title": "ecommerce",
              "src": "/static/e9388b3749c2873772a0d53bcd822dea/01645/ecommerce-1.png",
              "srcSet": ["/static/e9388b3749c2873772a0d53bcd822dea/5a46d/ecommerce-1.png 300w", "/static/e9388b3749c2873772a0d53bcd822dea/0a47e/ecommerce-1.png 600w", "/static/e9388b3749c2873772a0d53bcd822dea/01645/ecommerce-1.png 1091w"],
              "sizes": "(max-width: 1091px) 100vw, 1091px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} right={<div>
        <h5>🛒 E-commerce</h5>
        <p>An online shopping experience with shopping cart, checkout, and  customer sign up/log in. Designed to be responsive, it runs on all browsers and mobile devices. Built with React front-end, Firebase is used for storing user and shop data.</p>
        <Chips chips={['React', 'Redux', 'Node.js', 'Express.js', 'Firebase', 'Stripe']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://some-random-shopping-site.herokuapp.com'
      }, {
        label: 'Github',
        color: 'secondary',
        href: 'https://github.com/RobinRobinG/ecommerce-react-app'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#fcfcfc"} mdxType="Section">
      <TwoColumnsGrid right={<ImageContainer width={'100%'} border={1} borderColor={'primary.light'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "957px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "74%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAB40lEQVQ4y5WTy47TQBBF+8PZ8QMsEI8tEiuWsGCBEGgECx4SIEXADEIZoWTsxDG223a7X3WpatvSRAnRTJSrbruqT25VV1T+exEv1kusspLyzRa7okBxS223BWWbghkFqVp3WF619HOlsSk1Wq3RNCx9M2nW31pjXWha5hpq13oCIihGCiHCB7qVnI8iokgYXCRVtBGdJTALHIMZXEq6KVDOzbLsTUXeOC+BMdgbm6DWRVY4qcGGBBEjM1zNm3mtuX99b/iAh7XupMzAP24jxFScXKrr1lNPGATitoLG9YgoxRkQBEbgL15fGFyWHmqsfwQ6ActGcNMhOiaBysq2DLfm8x+Le28bvPk1jA4bIy6nsq8BTyolRXxbWTz70uPBO41X5+aw5H3gYcXyiVOs40t5+qnF4/ca9880vmduvpT/lXyouRUTGk8+ajxi4IdLm94oge0PamBogOfVTfKTZFQEcp47PDwzrC65qo30kiAzrawHTYOZVrn+dmBwwN7Q+lTB6GtxFXDnRYe7L3tU/VjQj9zj+cKQagzPk7U0OIdZX1cd1vXAgPG545mrestDzLn8HKNjhk+SeMN/ht46qjlH7ZrCZ7uMbdf8ooaoHWpOGvfHVLHKrkLZVumZc0nytanjPxnyi9lWQfyIAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "notes",
              "title": "notes",
              "src": "/static/fb98f7ce3108db1bc286d47f1a7688f5/6bff2/notes-4.png",
              "srcSet": ["/static/fb98f7ce3108db1bc286d47f1a7688f5/5a46d/notes-4.png 300w", "/static/fb98f7ce3108db1bc286d47f1a7688f5/0a47e/notes-4.png 600w", "/static/fb98f7ce3108db1bc286d47f1a7688f5/6bff2/notes-4.png 957w"],
              "sizes": "(max-width: 957px) 100vw, 957px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} left={<div>
        <h5>🗒️ Scratchpad</h5>
        <p>A simple app for taking notes anywhere, whether on your laptop or on your phone. Users can create multiple boards to organize their notes, and these boards can be shared with other users. Built with React and Material-Ui on the front-end, AWS Lambda for backend APIs, and DynamoDB for storing user data.</p>
        <Chips chips={['React', 'Material-Ui', 'AWS Lambda', 'API Gateway', 'DynamoDB']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://affectionate-easley-62f1fb.netlify.com/board/6a68a15b-641d-4749-84b9-7467498042b8'
      }, {
        label: 'Github',
        color: 'secondary',
        href: 'https://github.com/RobinRobinG/some-new-page'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#D0CDE1"} mdxType="Section">
      <TwoColumnsGrid left={<ImageContainer width={'100%'} border={1} borderColor={'#D0CDE1'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "983px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAEI0lEQVQ4yyWSbWxTZRiG+8PExESNwZiYEAVRFBERAsw4ooMfIqwwgTEGY8BEtvExycSNaAAhYiREon8myObka2MtjE7asbGNUdau3U6/v7u2rJ/rWOd0gx8mAufyXX2TJ++bc97nOvd9P0fRdP6HJ5du1KG6fk5WtV1ArVHRqlWhab9CW8d0tWAY7KHrTg83Ojpp7+5E26niqrqZ5qbLXLjwOw2NZ+Uz5+poqG94oshdO5NdB1fKew/nUXWkgKI9y8jfMZeN5Uso2LWIsq9W8qvqGAeP17CutFS8LyMvfxnvL1vAwvnzmPXKTJ55/imem/GsPOe1V1F8WDBb3lD+Dru+ni+XHHydwr1zKKteRe2J7Wzfv4ZvfizmjPoAx36uRbm9lA27S1mam8PipYt5VwDfnvcGL704Q37rvVmsL/pAVixY/jKFlTl8snU28z96mrk5LwglK9hWqWRJ3puU1eZy+uJOfmo8ycXrbdS3XGaFMp+FOYtYnZ/Hx8pclIW57Kv5lBOnSlFs2b2c6sOb2P9lCZ/t2UF5VQU1R6upFVVe9Tmn6w/RbVAz9XCK6fVEhomph3Tc1rGnajdr1q9kZ8Uqvj1ezqGjAth8/hd011rR9+hx2j0EAyFCoTCxWJx0Ok0smmBy8gGTD/4hkhgjGLtPOvM/3O300dZylaZGMZi6Ok4d+w6Fy2oTEA/R6D0SyRgj6YQ4DxMMBhgaChGLR0mMpDEO2NDeMqLR6Wnv6ieWGuffR4+Y+CtDZnyUpOiNhAMoPB4X0diwUDXEcDRMOBLC7XLT32/OlsclVHtDDJpdaFq7aWy8hs0ZZGTsb8YnphgdTZNKJYUjwQgPTQPdmE0D2O02HA672B14PD4kyYbL4UYv6ely9qCzdHDm7EmOVO2jovIAuh6JsT8nBSxOUgDj8VjWlcJisWA2m3A6bLjdLgHzEAgEsTuchAP3aLGoqe6qoaqjmu/Vx1m/eiN7931By/Vu4qn7wqafRPweycQwCVEKnVaHyWTC63Nnv+D3+/F6fdhsdiSLnYBviLH0BEGXD4eIoLX5CgPGuzhcAYLhaFaIX8QWGfKLqOwopEEzPq+b6SyD4mEkEsbn8wqoJ2vd6fSQEtN22e30dnbSo/2DmxoNA5JbZJ/Muurt1TNgMjMohCmuqq/J7bp2DIY+2SINYLVKSIMSfX1GjAaT+AMCjGcyeNwBum5oua1ppbOjj0FrUFiNiaFZsUpm+U5vj4D2y4qbt25icdlkh9eJ1WnB6XMhOaxYHBZcfg+hWITk/RH6+iV+a2yivuEyKm0fLVoDulu3MZoNGE0G9P13ZYPZiCK9pfjxWMlWRrcUy6miTaSLN5MqKsruY6XbyFSUk6msIFayjeDGTQSU6wivLSCoLMC7ag1J0SPuyyObN5MoLHz8H2rGdzm6B6U1AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "chewbebe",
              "title": "chewbebe",
              "src": "/static/28c31b0b7b77b50b83999e158df5664d/91b29/chewbebe-1.png",
              "srcSet": ["/static/28c31b0b7b77b50b83999e158df5664d/5a46d/chewbebe-1.png 300w", "/static/28c31b0b7b77b50b83999e158df5664d/0a47e/chewbebe-1.png 600w", "/static/28c31b0b7b77b50b83999e158df5664d/91b29/chewbebe-1.png 983w"],
              "sizes": "(max-width: 983px) 100vw, 983px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} right={<div>
        <h5>🍪 Chewbebe</h5>
        <p>An e-commerce site for promoting healthy treats for all dogs and cats. The specifications of the project included creating the branding, packaging, and all other marketing related materials for the company, as well as all of the front-end web design and implementation. The site is built on Shopify, an e-commerce CMS platform to make future updates and maintenance easier for the client.</p>
        <Chips chips={['Shopify', 'HTML', 'CSS', 'JavaScript', 'Marketing', 'SEO', 'Visual Design', 'Illustration']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://www.chewbebe.com'
      }]} mdxType="Buttons" />
    </Section>
    <Section background={"#fcfcfc"} mdxType="Section">
      <TwoColumnsGrid right={<ImageContainer width={'100%'} border={1} borderColor={'primary.light'} mdxType="ImageContainer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1073px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "73.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAD5ElEQVQ4y6WS60/TZxiGf//CkmWbJnPZFjfUCBiZrghTcIDAQI5uHORUGLSchiAiHUcLKLT0DJSjUGihHCK0gByGqBFNx2TNlomaDdyicQugf8Dg2m9mH/Zln/YmV947T573zpPnfoX1Xx5uu39b58Xm5s7Gxgabm5tsbW29uv/NC7G29R+8+Ie/tfA7sAU7f/L/z/b2NkKDqnmnqVmDQavZ6W4z0GXSYDZoaDfpMBt1tBkNmEVt6TTR1WbEpNdi1Gkx6TS0GbSv+mx9PdgG+rEPjyAc2vs6nu++xgnPXZTF7qc44j0+99/D6Y/fJvjwHgK8dnPm2DuoMo4gPXUA331v4Lt/FycOvsVnPm+S5L+b6jgP8iJ9OO3vhTBtljFvzsGpiufZgoG1qQYWTUksmDO51pLHcckBClJCeemysNxfwmB9PCOaDGbEdzdNCazaCng2q2axMZEWmT/CqmjweLqBaUM6z90zPHUN83DOwH2nGmPFF5yNCiMlJpxHP3zL2o+36NSWUlcSx8CVZB5MXmJzxc4T1yQRR/bi5/U+wi1LCY9mmli2V7J2q4en7uv88fgeD+5OMO8cwmGopakgBYteyfLsMOPDnXTpzrNgVbDunmbNPcuT2710F4TxVbwEwdl5ju/GLuGyl/PrXQvPV2/ys2sG9zfj/LS6ylS3jl4xqNmxXiZba5ixtXDn+gDf37Zzf3kO1w0rKw4jbnMBPUUBCBZNFramROa7Zbgnapjrq2K0uZxBTRWOayPUXcin9GI5SQnxpEUHMmFtZW60i47GMgb0Cpw9tdhV51ioS6Ah+xCCtiKWPnHRTtOXzLZlcbUymdrsaOrqa2gfHGF5ZQXn5BRW8Vu47tzA3qNHWy2jUAwqIdiHrJgQihND6RUDUZw5itBSn05rhbjk2jgW27IZVRUy1Cxn/oaD8w06rEOjTEw4cTicvNzawDHYQWlGOOoLKfQ2SMkN96YoZD8DORKqIzwQlLknMRYHYamKZLwpje4aOUOqVFovJSNNjSY94yzyonPIZFlYjbXcWRhjdkSLTZ8vGiZRL/VDK2LOlFB4SjSsyTyGWu5Pv2hoqUrhcm4C43op7fVpNFZmYOtRsXRvkYmRPpoVeVw1KLGaymlVpqJXxGAuC8deEYk2XUJq4AcIVaJQyT6hqyyMJXM2mrwI7FdSMakVREVHcdTTgxO+h/lU4s3XObF0iAHUFMZQkROKMk8k5yT26jgM2QEkB4oTVqZJUEp90eWfZKk1k+bcUNRFUVxWXqSwQE7AES+OHvyQ494epEf6kZ8URFqEhMQQHzIjPyIxyBuNPJi6FD/OHN/HXzLxK4RNKWt5AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kevinngyoga",
              "title": "Kevinngyoga",
              "src": "/static/4137e32378694bbffd3cc55b5930b9ea/e24fe/kevinngyoga-1.png",
              "srcSet": ["/static/4137e32378694bbffd3cc55b5930b9ea/5a46d/kevinngyoga-1.png 300w", "/static/4137e32378694bbffd3cc55b5930b9ea/0a47e/kevinngyoga-1.png 600w", "/static/4137e32378694bbffd3cc55b5930b9ea/e24fe/kevinngyoga-1.png 1073w"],
              "sizes": "(max-width: 1073px) 100vw, 1073px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </ImageContainer>} left={<div>
        <h5>🧘🏻 Kevin Ng Yoga</h5>
        <p>A site for a yoga instructor Kevin Ng to showcase his work and events. Features include a calendar to show all upcoming events, albums to display highlights and photos from all past events, a newsletter signup form, and a shop for merchandise.</p>
        <Chips chips={['Squarespace', 'HTML', 'CSS', 'JavaScript']} mdxType="Chips" /></div>} mdxType="TwoColumnsGrid" />
      <Buttons buttons={[{
        label: 'Visit Site',
        color: 'primary',
        href: 'https://www.kevinngyoga.com/'
      }]} mdxType="Buttons" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      